<template lang="pug">
b-col(md="12")
  b-form.repeater-form(
    ref="form",
    :style="{ height: trHeight }",
    @submit.prevent="repeateAgain"
  )
    // Row Loop
    b-row.mt-0(
      v-for="(item, index) in items",
      :id="item.id",
      :key="item.id",
      ref="row"
    )
      // Domain
      b-col(md="2")
        b-form-group(:label="$t('message.domain')", label-for="v-improvement-domain")
          validation-provider(:name="`${$t('message.domain')} ${index + 1}`", rules="required", v-slot="{ errors }")
            b-form-select#v-improvement-domain(
              v-model="item.domain",
              :options="domains",
              :selectable="(option) => !option.value.includes('select_value')",
              label="text"
              @change="emitUpdateImprovements"
            )
            small(class="text-danger", :style="{opacity: errors.length}") {{ errors[0] || "error" }}
      // Note
      b-col(md="4")
        b-form-group(:label="$t('message.note')", label-for="v-improvement-note")
          validation-provider(:name="`${$t('message.note')} ${index + 1}`", rules="required", v-slot="{ errors }")
            b-form-input#v-improvement-note(
              v-model="item.note",
              @change="emitUpdateImprovements"
            )
            small(class="text-danger", :style="{opacity: errors.length}") {{ errors[0] || "error" }}
      // Assignee
      b-col(md="3")
        b-form-group(:label="$t('label.assignee')", label-for="v-improvement-assignee")
          v-select#v-improvement-assignee(
            v-model="item.assignee",
            :options="workers",
            label="name"
            @change="emitUpdateImprovements"
          )
      // Due date
      b-col(md="2")
        b-form-group(:label="$t('label.due_date')", label-for="v-improvement-dueDate")
          flat-pickr#v-improvement-dueDate(
            v-model="item.dueDate",
            class="form-control",
            @change="emitUpdateImprovements"
          )
      // Metadata
      b-col(v-for="metadata in item.metadata" :key="metadata._id", :md="metadata.columnWidth ? metadata.columnWidth : 3")
        b-form-group(
          :label="$t(`metadata.${metadata.name}`)"
          :label-for="$t(`metadata.${metadata.name}`)"
          description=""
        )
          v-select(
            v-if="metadata.type === 'select'"
            v-model="metadata.answer"
            :multiple="false"
            :options="metadata.options"
          )
          b-form-input(
            v-if="metadata.type === 'input'"
            v-model="metadata.answer"
          )
          flat-pickr(
            v-if="metadata.type === 'datepicker'"
            v-model="metadata.answer"
            class="form-control"
          )
      // Remove button
      b-col.mb-50(md="1")
        b-button.mt-0.mt-md-2(
          v-ripple.400="'rgba(234, 84, 85, 0.15)'",
          variant="outline-danger",
          @click="removeItem(index)"
        )
          feather-icon.mr-25(icon="XIcon")
  b-button(
    v-ripple.400="'rgba(255, 255, 255, 0.15)'",
    variant="primary",
    @click="repeateAgain"
    class="mb-1"
  )
    feather-icon.mr-25(icon="PlusIcon")
    span {{ $t('message.Add_Improvement') }}
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import i18n from "@/libs/i18n";
import { ValidationProvider } from 'vee-validate';
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect,
    ValidationProvider,
    flatPickr,
  },

  props: {
    domains: {
      type: Array,
      required: true,
    },
    workers: {
      type: Array,
      required: true,
    },
    metadata: {
      type: Array,
      required: true,
    },
  },

  directives: {
    Ripple,
  },

  mixins: [heightTransition],

  data() {
    return {
      emails: [
        { value: 'jtguzman@addval.cl', text: 'JT Guzman' },
        { value: 'dherrmann@addval.cl', text: 'D Herrmann' },
        { value: 'ggomez@addval.cl', text: 'G Gomez' },
      ],
      items: [],
      nextTodoId: 1,
    }
  },

  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
        metadata: this.metadata.map(e => ({
          name: e.name,
          type: e.type,
          options: e.options,
          answer: e.answer,
          columnWidth: e.columnWidth,
        }))
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.emitUpdateImprovements()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    emitUpdateImprovements() {
      this.$emit('update-improvements', this.items)
    },
  },
}
</script>
