<template lang="pug">
b-col(md="12")
  b-form.repeater-form(
    ref="form",
    :style="{ height: trHeight }",
    @submit.prevent="repeateAgain"
  )
    // Row Loop
    b-row.mt-0(
      v-for="(item, index) in items",
      :id="item.id",
      :key="item.id",
      ref="row"
    )
      // Attendee Name
      b-col(md="8")
        b-form-group(
          :label="$t('message.attendee_no', 0, {count: (index + 1)})",
          label-for="v-attendee-name"
        )
          b-form-select#v-responsible(
            v-model="item.attendee",
            :options="emails",
            :selectable="(option) => !option.value.includes('select_value')",
            label="text"
          )
      b-col(md="2")
        b-form-group(:label="$t('message.presence')", label-for="v-attendee-presence")
          b-form-select#v-attendee-presence(
            v-model="item.presence",
            :options="presences",
            :selectable="(option) => !option.value.includes('select_value')",
            label="text"
          )
      // remove button
      b-col.mb-50(md="2")
        b-button.mt-0.mt-md-2(
          v-ripple.400="'rgba(234, 84, 85, 0.15)'",
          variant="outline-danger",
          @click="removeItem(index)"
        )
          feather-icon.mr-25(icon="XIcon")
  b-button(
    v-ripple.400="'rgba(255, 255, 255, 0.15)'",
    variant="primary",
    @click="repeateAgain"
  )
    feather-icon.mr-25(icon="PlusIcon")
    span {{ $t('message.Add_Attendee') }}
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect,
  },

  directives: {
    Ripple,
  },

  mixins: [heightTransition],

  data() {
    return {
      emails: [
        { value: 'jtguzman@addval.cl', text: 'JT Guzman' },
        { value: 'dherrmann@addval.cl', text: 'D Herrmann' },
        { value: 'ggomez@addval.cl', text: 'G Gomez' },
      ],
      presences: [
        { value: 'presencial', text: 'Presencial' },
        { value: 'virtual', text: 'Virtual' },
      ],
      items: [],
      nextTodoId: 1,
    }
  },

  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    emitUpdateBehaviours() {
      this.$emit('update-behaviours', this.items)
    },
  },
}
</script>
