import { ref, onMounted, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import useCommon from "@/views/organization/useCommon"
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import awsConnection from '@/views/habit/aws'
import endpoints from '@/libs/endpoints'
import realmConnection from '@/views/habit/realm'

export default function useCommunications() {

    /*----------------- General -----------------*/
    const { getLocationsForDropDown, locations, openImage, formatIsoDate } = useCommon()
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { router, route } = useRouter()
    const { singleUpload, getFile } = awsConnection()
    const { getItemsWithAggregate, updateItem, createItem, ObjectId } = realmConnection()
    const role = store.state?.userStore?.userData.role
    const client_id = role !== "admin" ? store.state?.userStore?.userData.client.$oid : null
    const isOnline = computed(() => store.state.app.isOnline)
    const isLoading = ref(true)
    const compressor = ref(null)
    const collection = 'communication'

    // Parse data from back call
    const parseData = async (e) => {
        if (!e) return
        e.dateFrom = formatIsoDate(e.dateFrom)
        e.dateTo = formatIsoDate(e.dateTo)
        e.locations?.sort((a, b) => {
            if (a.location < b.location) return -1
            if (a.location > b.location) return 1
            return 0
        })
        // To show the preview in the image tag
        if (e.imageKey) {       
            try {
                const src = await getFile(endpoints.aws_bucket, e.imageKey)
                e.src = src
            } catch (error) {
                console.log(error)
                // error.startsWith('NoSuchKey')
            }
        }
    }
    
    const uploadImage = () => {
        compressor.value.$el.click()
    }

    const getImg = (obj) => {
        imgData.value = obj.compressed
    }

    // Settings for the quill-editor
    const editorOptions = {
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['clean']
          ]
        },
        placeholder: i18n.t('message.WriteDdescription')
    }

    /*----------------- List communications -----------------*/
    const searchQuery = ref('')
    const perPage = ref(10)
    const currentPage = ref(1)
    const communications = ref([])
    const totalCommunications = ref(0)
    const sortBy = ref('title')
    const isSortDirDesc = ref(false)
    const dataExcel = ref([])

    const tableColumns = computed(() => {
        return [
            { key: 'title', label: i18n.t('message.tableHeader.title'), sortable: true },
            { key: 'location', label: i18n.t('message.tableHeader.locations'), sortable: true },
            { key: 'dateFrom', label: i18n.t('message.tableHeader.dateFrom'), sortable: true },
            { key: 'dateTo', label: i18n.t('message.tableHeader.dateTo'), sortable: true },
            { key: 'detail', label: i18n.t('message.tableHeader.detail'), sortable: true },
            { key: 'imageKey', label: i18n.t('message.tableHeader.img') },
            { key: 'actions', label: i18n.t('message.tableHeader.actions') },
        ]
    })

    const listCommunications = async (actualDate, location, searchValue) => {
        if (searchValue && searchValue !== searchQuery.value) return // To avoid early updates of the search when the user is still typing

        isLoading.value = true

        const query = {
            client_id: ObjectId(client_id),
            deleted: { $ne: true }
        }

        if (actualDate) {
            actualDate.setHours(0, 0, 0, 0)
            query.dateFrom = { $lte: actualDate }
            query.dateTo = { $gte: actualDate }
        }      

        if (location) {
            query.locations = ObjectId(location.value)
        }

        const pipeline = [
            { $match: query },
            { $lookup: { from: 'location', localField: 'locations', foreignField: '_id', pipeline: [ { $project: { location: 1 } } ], as: 'locations' } },
            { $sort: { dateFrom: 1 } },
            { $addFields: { _id: { $toString: "$_id" } } }
        ]

        try {
            let backData = await getItemsWithAggregate({ collection, pipeline })
            
            if (searchQuery.value) {
                backData = backData?.filter(e => e.title?.toLowerCase().includes(searchQuery.value.toLowerCase()))
            }

            communications.value = await Promise.all(backData?.map(async (e) => {
                await parseData(e)
                return e
            }))
            totalCommunications.value = communications.value.length

            parseDataForExcel(communications.value)
        } catch (error) {
            console.log(error)
        } finally {
            if (!searchValue || searchValue === searchQuery.value) isLoading.value = false
        }
    }

    const parseDataForExcel = (communcations) => {
        const parseCommunications = communcations.map(c => {
            const { title, dateFrom, dateTo, detail, locations } = c
            const parseLocations = locations.map(loc => loc.location).join(' / ')
            const newObject = { title, dateFrom, dateTo, detail, locations: parseLocations }
            return newObject
        })
        dataExcel.value = parseCommunications
    }

    // Fields to download the excel
    const communicationsFields = ref({
        [i18n.t('message.tableHeader.title')]: "title",
        [i18n.t('message.tableHeader.locations')]: "locations",
        [i18n.t('message.tableHeader.dateFrom')]: "dateFrom",
        [i18n.t('message.tableHeader.dateTo')]: "dateTo",
        [i18n.t('message.tableHeader.detail')]: "detail",
    })

    const searchCommunication = searchValue => {
        isLoading.value = true
        listCommunications(null, null, searchValue)
    }

    const showingMessage = computed(() => {
        const total = totalCommunications.value
        const from = ((currentPage.value - 1) * perPage.value) + 1
        const to = Math.min(currentPage.value * perPage.value, total)
        return i18n.tc('message.paginationText', 0, { from, to, total })
    })
       
    /*----------------- Add and Edit communication -----------------*/
    const now = new Date()
    const [y, m, d] = now.toISOString().substring(0, 10).split('-')
    const reverseDate = `${d}-${m}-${y}`
    const dateRange = ref(`${reverseDate} to ${reverseDate}`)
    const form = ref(null)
    const imgData = ref(null)
    const selectedLocations = ref([])
    const addOrEditComm = ref({ title: '', detail: '' })
    const imgName = ref('ver')
    const imgDeleted = ref(false)
  
    const datePickerConfig = ref({
        mode: "range",
        dateFormat: default_language === "en" ? "m-d-Y" : "d-m-Y",
    })
    
    const parseDate = (dateRange) => {
        const objDate = new Date(dateRange[2], dateRange[default_language === "en" ? 0 : 1] - 1, dateRange[default_language === "en" ? 1 : 0])
        const year = objDate.getFullYear()
        const month = objDate.getMonth()
        const day = objDate.getDate()
        const date = new Date(year, month, day)
        return date
    }    
   
    const validateForm = () => new Promise((resolve, reject) => {      
        form.value.validate().then(success => {
            if (success) resolve(true)
            else reject()
        })
    })

    const saveImgAWS = (img) => {
        const destinationFolder = `${client_id}/communications`
        return new Promise((resolve, reject) => {
          singleUpload(img, destinationFolder)
            .then((key) => resolve(key))
            .catch((err) => reject(err))
      })
    }

    const validateAndSubmit = async (e, val) => {
        try {
            await validateForm()          
        } catch (error) {
            showErrorMessage(i18n.t('message.requiredFieldsIncomplete'))
            return
        }

        const payload = {
            title: addOrEditComm.value.title || '',
            detail: addOrEditComm.value.detail || '',
        }

        if (imgData.value) {
            const key = await saveImgAWS(imgData.value)
            payload.imageKey = key
        } else if (imgDeleted.value) {
            payload.imageKey = null
        }

        // Date Range: by default it comes with 'to', but if you click on the same number for the from date and the to date, it arrives without 'to'
        let from, to
        if (dateRange.value.includes(' to ')) {
            const datesArray = dateRange.value.split(" ")
            from = datesArray[0].split("-")
            to = datesArray[2].split("-")
        } else {
            from = to = dateRange.value.split("-")
        }
        payload.dateFrom = parseDate(from)
        payload.dateTo = parseDate(to)

        const locationId = locations.value?.map(l => ObjectId(l.value)) || []
        const loc = allLocations.value ? locationId : selectedLocations.value?.map(e => ObjectId(e)) || []
        payload.locations = loc

        if (val === 'add') payload.client_id = ObjectId(client_id)
        const msj = val === 'add' ? i18n.t('message.communicationCreated') : i18n.t('message.communicationUpdated')

        try {
            if (val === 'add') await createItem({ collection, payload })
            else {
                const query = { _id: ObjectId(route.value.params.id) }
                const action = { $set: payload }
                await updateItem({ collection, query, action })
            }

            showSuccessMessage(msj)
            listCommunications()
            router.push({ name: 'habit-meeting-communications' })
          } catch (error) {
            console.log(error)
            showErrorMessage(i18n.t('message.communication_error'))
          }
    }

    /*----------------- View and Edit communication -----------------*/
    const { id } = route.value.params
    const viewCommunication = ref({})
    const allLocations = ref(false)

    const getOneCommunication = async (val) => {
        isLoading.value = true
        try {
            const query = { _id: ObjectId(id) }
            const pipeline = [
                { $match: query },
                { $lookup: { from: 'location', localField: 'locations', foreignField: '_id', pipeline: [ { $project: { location: 1 } } ], as: 'locations' } },
            ]
    
            const items = await getItemsWithAggregate({ collection, pipeline })
            if (!items?.[0]) throw new Error('Item not found')

            const e = items[0]

            if (val === 'edit') {
                // Extract values from location and put them in the v-select
                const locationValues = e.locations.map(loc => {
                    const found = locations.value.find(l => l.title === loc.location);
                    return found ? found.value : null;
                }).filter(val => val !== null)
                selectedLocations.value = locationValues

                // Show current image name
                if (e.imageKey) {
                    const parts = e.imageKey.split('/')
                    imgName.value = parts[2]
                }

                // Show communication dates
                dateRange.value = default_language === "en"
                    ? `${e.dateFrom.getMonth() + 1}-${e.dateFrom.getDate()}-${e.dateFrom.getFullYear()} to ${e.dateTo.getMonth() + 1}-${e.dateTo.getDate()}-${e.dateTo.getFullYear()}`
                    : `${e.dateFrom.getDate()}-${e.dateFrom.getMonth() + 1}-${e.dateFrom.getFullYear()} to ${e.dateTo.getDate()}-${e.dateTo.getMonth() + 1}-${e.dateTo.getFullYear()}`
                
                addOrEditComm.value = e
            } else {
                await parseData(e)
                viewCommunication.value = e
            }
        } catch (error) {
            console.log(error)
        } finally {
            isLoading.value = false
        }
    }

    const deleteImage = () => {
        addOrEditComm.value.imageKey = null
        imgDeleted.value = true
    }

    const handleCheckboxChange = () => {
        allLocations.value = !allLocations.value
    }

    /*----------------- Delete communication -----------------*/
    const deleteCommunication = async (idFromList) => {
        isLoading.value = true

        const query = { _id: ObjectId(idFromList || id) }
        const action = { $set: { deleted: true } }

        try {
            await updateItem({ collection, query, action })
    
            showSuccessMessage(i18n.t('message.communicationRemoved'))
            listCommunications()
            if (!idFromList) router.push({ name: 'habit-meeting-communications' })
        } catch (error) {
            console.log(error)
            showErrorMessage(i18n.t('message.communication_error'))
        } finally {
            isLoading.value = false
        }
    }

    /*----------------- Vue.js functions -----------------*/
    onMounted(() => {
        getLocationsForDropDown()
    })
    
return {
        role,
        isOnline,
        isLoading,
        showingMessage,
        locations,
        compressor,
        uploadImage,
        getImg,
        searchQuery,
        communications,
        tableColumns,
        sortBy,
        isSortDirDesc,
        perPage,
        totalCommunications,
        currentPage,
        dateRange,
        form,
        validateAndSubmit,
        datePickerConfig,
        addOrEditComm,
        imgData,
        selectedLocations,
        openImage,
        listCommunications,
        router,
        viewCommunication,
        getOneCommunication,
        deleteCommunication,
        editorOptions,
        imgName,
        deleteImage,
        handleCheckboxChange,
        allLocations,
        searchCommunication,
        communicationsFields,
        dataExcel
    }

}